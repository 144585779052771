import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { registerLocaleData } from '@angular/common';
import { en_US, NZ_I18N } from 'ng-zorro-antd/i18n';
import en from '@angular/common/locales/en';
import { API_BASE_URL } from './core/services/service-proxies';
import { SharedModule } from './shared/shared.module';
import { CoreModule } from './core/core.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NZ_CONFIG, NzConfig } from 'ng-zorro-antd/core/config';
import { NzSliderModule } from 'ng-zorro-antd/slider';
import { environment } from '../environments/environment';

registerLocaleData(en);

const ngZorroConfig: NzConfig = {
  pageHeader: {
    nzGhost: false,
  },
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    CoreModule,
    SharedModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NzSliderModule,
  ],
  providers: [
    {
      provide: API_BASE_URL,
      multi: false,
      useValue: environment.apiUrl,
    },
    { provide: NZ_I18N, useValue: en_US },
    { provide: NZ_CONFIG, useValue: ngZorroConfig },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
