import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'administration',
    loadChildren: () =>
      import('./modules/administration/administration.module').then(
        (m) => m.AdministrationModule,
      ),
  },
  {
    path: 'company',
    loadChildren: () =>
      import('./modules/company/company.module').then((m) => m.CompanyModule),
  },
  {
    path: 'leave-management',
    loadChildren: () =>
      import('./modules/leave-management/leave-management.module').then(
        (m) => m.LeaveManagementModule,
      ),
  },
  {
    path: 'recruitment',
    loadChildren: () =>
      import('./modules/recruitment/recruitment.module').then(
        (m) => m.RecruitmentModule,
      ),
  },
  {
    path: 'resources',
    loadChildren: () =>
      import('./modules/resources/resources.module').then(
        (m) => m.ResourcesModule,
      ),
  },
  {
    path: 'candidates',
    loadChildren: () =>
      import('./modules/candidates/candidates.module').then(
        (m) => m.CandidatesModule,
      ),
  },
  {
    path: 'interviews',
    loadChildren: () =>
      import('./modules/interviews/interviews.module').then(
        (m) => m.InterviewsModule,
      ),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
