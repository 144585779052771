import { Component, OnInit } from '@angular/core';
import { MenuItem } from './core/sidebar-menu/menu-item';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'hrm';
  menuItems: MenuItem[] = [];
  isCollapsed = false;

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.initializeMenuItems();
  }

  isMenuActive(item: MenuItem): boolean {
    if (!item.children) return false;

    return item.children.some((v: MenuItem): boolean => {
      if (!v.routerLink) {
        return false;
      }
      return this.router.isActive(v.routerLink, {
        fragment: 'ignored',
        queryParams: 'ignored',
        matrixParams: 'ignored',
        paths: 'subset',
      });
    });
  }

  private initializeMenuItems(): void {
    this.menuItems = [
      new MenuItem('Dashboard', '/dashboard', 'dashboard', false, undefined),
      new MenuItem(
        'Candidates',
        '/candidates',
        'usergroup-add',
        true,
        undefined,
      ),
      new MenuItem('Company', 'company', 'windows', true, [
        new MenuItem('Departments', 'departments', null, true, undefined),
        new MenuItem('Employees', 'employees', null, true, undefined),
        new MenuItem('Teams', 'teams', null, true, undefined),
      ]),
      new MenuItem('Leave Management', 'leave-management', 'group', true, [
        new MenuItem('Leaves', 'leaves', null, true, undefined),
        new MenuItem('Leave Types', 'leave-types', null, true, undefined),
      ]),
      new MenuItem('Recruitment', 'recruitment', 'message', true, [
        new MenuItem('Job Positions', 'job-positions', null, true, undefined),
        new MenuItem(
          'Recruitment Stages',
          'recruitment-stages',
          null,
          true,
          undefined,
        ),
      ]),
      new MenuItem('Resources', 'resources', 'sliders', true, [
        new MenuItem('Meeting Rooms', 'meeting-rooms', null, true, undefined),
        new MenuItem(
          'Equipment Requests',
          'equipment-requests',
          null,
          true,
          undefined,
        ),
      ]),
      new MenuItem('Interviews', '/interviews', 'calendar', true, undefined),
      new MenuItem('Administration', 'administration', 'code', true, [
        new MenuItem('Tags', 'tags', null, true, undefined),
        new MenuItem('Color Tags', 'color-tags', null, true, undefined),
        new MenuItem('Currencies', 'currencies', null, true, undefined),
        new MenuItem('Work Hours', 'work-hours', null, true, undefined),
        new MenuItem(
          'Equipment Request Types',
          'equipment-request-types',
          null,
          true,
          undefined,
        ),
      ]),
    ];
  }
}
