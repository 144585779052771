<nz-layout class="app-layout">
  <nz-sider
    class="menu-sidebar"
    nzCollapsible
    nzWidth="256px"
    nzBreakpoint="md"
    [(nzCollapsed)]="isCollapsed"
    [nzTrigger]="null"
    nzTheme="dark"
  >
    <div class="sidebar-logo">
      <a routerLink="./">
        <img src="https://ng.ant.design/assets/img/logo.svg" alt="logo" />
        <h1>HRM</h1>
      </a>
    </div>
    <ul
      nz-menu
      nzMode="inline"
      [nzInlineCollapsed]="isCollapsed"
      nzTheme="dark"
    >
      @for (item of menuItems; track item.routerLink) {
        @if (item.children.length) {
          <li
            nz-submenu
            [nzOpen]="isMenuActive(item)"
            [nzTitle]="item.name"
            [nzIcon]="item.iconClass"
            [nzDisabled]="!item.enabled"
          >
            <ng-container *ngIf="item.children.length">
              <ul>
                @for (subItem of item.children; track subItem.routerLink) {
                  <li
                    [nzDisabled]="!subItem.enabled"
                    nz-menu-item
                    [nzMatchRouter]="subItem.routerLink"
                  >
                    <a
                      [routerLink]="item.routerLink + '/' + subItem.routerLink"
                      >{{ subItem.name }}</a
                    >
                  </li>
                }
              </ul>
            </ng-container>
          </li>
        } @else {
          <li
            class="ant-menu-submenu-vertical"
            [routerLink]="item.routerLink"
            nz-menu-item
            [nzDisabled]="!item.enabled"
            [nzMatchRouter]="item.routerLink"
          >
            <span nz-icon [nzType]="item.iconClass ?? ''"></span>
            <span [routerLink]="item.routerLink">{{ item.name }}</span>
          </li>
        }
      }
    </ul>
  </nz-sider>
  <nz-layout>
    <nz-header>
      <div class="app-header">
        <span
          tabindex="0"
          class="header-trigger"
          (click)="isCollapsed = !isCollapsed"
          (keydown.enter)="isCollapsed = !isCollapsed"
        >
          <span
            class="trigger"
            nz-icon
            [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'"
          ></span>
        </span>
      </div>
    </nz-header>
    <nz-content>
      <div class="page-container">
        <div class="main-container">
          <router-outlet></router-outlet>
        </div>
      </div>
    </nz-content>
  </nz-layout>
</nz-layout>
