/**
 * Represents a menu item.
 */

export class MenuItem {
  /**
   * Create a new instance of MenuItem
   * @param name The display name of the menu
   * @param routerLink The route that will be applied to [routerLink]
   * @param iconClass The menu icon class (optional)
   * @param enabled Whether the menu item is enabled (optional)
   * @param children Child menu items (optional)
   */
  constructor(
    public name: string,
    public routerLink: string = '',
    public iconClass: string | null = null,
    public enabled: boolean = true,
    public children: MenuItem[] = [],
  ) {}
}
